@import '../../index.scss';

.login_screen {
  background-color: $buttonTextColor;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  padding: 20px;
  .login_container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
    .delete_message {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .login_wrapper {
      width: 25%;
      .login_logo_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 2em;
        .login_logo {
          width: 60%;
        }
      }
      .form_label{
        text-align: center;
      }
      .login_form {
        width: 100%;
        .delete_modal{
          border: none;
          border-radius: 16px;
          div{
            padding-inline: 10px;
            text-align: center;
            div{
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: space-around;
              .confirm_delete{
                background-color: $cancel;
              }
            }
          }
        }
        .delete_modal::backdrop {
          background-color: $focusOutLine;
          opacity: 0.5;
        }
        .form_control {
          margin: 1em 0;
        }
        .button_control {
          margin: 1em 0;
        }
      }
      .forget_password{
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .forget_password_button{
        cursor: pointer;
      }
    }
    @media screen and (max-width: 600px) {
      .login_wrapper {
        width: 80%;
      }
    }
    @media screen and (min-width: 600px) and (max-width: 1024px) {
      .login_wrapper {
        width: 40%;
      }
    }
    @media screen and (min-width: 1500px) {
      .login_wrapper {
        width: 20%;
      }
    }
  }
  .user_container{
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .user_container_title{
      font-size: 24px;
      padding: 20px;
    }
    .user_wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      .user_card_container{
        width: 46%;
        border-radius: 20px;
        margin: 10px;
        border: 1px solid $primaryDarkColor;
        display: flex;
      }
      .user_initial_container{
        width: 20%;
        background-color: $secondaryLightColor;
        border-radius: 12px;
        height: max-content;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        .user_initial{
          color: $secondaryDarkText;
          font-size: 20px;
          text-transform: uppercase;
        }
      }
      .user_details{
        width: 80%;
        .user_org_branch{
          display: flex;
          gap: 5px;
        }
        .user_name{
          color: $primaryDarkColor;
          font-size: 18px;
        }
        .user_email , .user_org_branch{
          font-size: 16px;
          color: $primaryLightText;
        }
      }
      .user_card_wrapper{
        width: 90%;
        display: flex;
        flex-direction: row;
        gap: 10px;
        cursor: pointer;
        padding: 15px;
        border-radius: 20px;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
      .user_card_close{
        margin: 15px 10px 0px 0px;
        width: 8%;
        height: fit-content;
        .close_button{
          cursor: pointer;
        }
      }
    }
  }
  .signin_container{
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .signin_button{
    cursor: pointer;
  }
}
